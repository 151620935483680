<template>
  <div style="padding-top: 11px;">
    <van-pull-refresh v-model="pullLoading" success-text="刷新成功" @refresh="onPullDownRefresh">
      <van-list v-model="loading" :finished="finished" finished-text="没有更多了" @load="getDataList"
                v-if="dataList.length > 0" offset="10">
        <div class="items" v-for="item in dataList" :key="item.id"
             @click="goUserInfo(item.bhrId,item.id,item.houseId,10,'租客',item.houses)">
          <div class="top">
            <div class="top-left" :class="{'isOld34': $isOld == 1}">{{ item.houses }}</div>
                    <div class="top-right" :class="{'isOld28': $isOld == 1,'colorsss':item.timesss}" >合同到期：{{item.rentFinishDate || '暂无'}}</div>
          </div>
          <div class="mid">
            <div class="mid-left">
              <img v-if="item.headImg" class="header" :src="getImageStream(item.headImg)" alt="">
              <img v-else class="header" :src="require('@/assets/img/newVersion/header.png')"/>
              <div class="personal-info">
                <div class="name" :class="{'isOld32': $isOld == 1}">{{ item.name }}</div>
                <div class="detail" :class="{'isOld32': $isOld == 1}">
                  <div class="sex">{{ item.sex == 1 ? '男' : '女' }}</div>
                  <div class="age">{{ item.age }}</div>
                  <div class="phone">{{ item.mobile }}</div>
                </div>
                <div class="idNumber">{{ item.idNumber }}</div>
              </div>
              <a :href="'tel:' + item.decode" @click.stop=""><img class="mobile"
                                                                  :src="require('@/assets/img/newVersion/mobile.png')"
                                                                  alt=""></a>
            </div>
            <img class="mid-right" :src="require('@/assets/img/newVersion/right.png')" alt="">
          </div>
          <div class="down" :class="{'isOld28': $isOld == 1}">
            <div class="down-left">入住时间：{{ item.rentStartDate || '暂无' }}</div>
            <div class="down-right" @click.stop="moveOutShow(item.bhrId,item.userName, item.userId)">搬离</div>
          </div>
        </div>
      </van-list>
      <van-image v-else :src="require('@/assets/img/empty.png')" style="margin-left:10%;width:80%;"></van-image>
    </van-pull-refresh>
    <div
        class="add-box"
        @click="goAdd(1)"
        @mousedown.stop="down"
        @touchstart.stop="down"
        @mousemove.stop="move"
        @touchmove.stop="move"
        @mouseup.stop="end"
        @touchend.stop="end"
        ref="fu"
    >
      <div class="add-img">
        <img class="add-icon" :src="require('@/assets/img/newVersion/add.png')" alt="">
        <div class="text">新增、添加</div>
      </div>
    </div>
    <van-dialog
        v-model="control.moveOutShow"
        cancel-button-text="取消"
        confirm-button-text="确定"
        show-confirm-button
        show-cancel-button
        @confirm="moveOut"
        :before-close="onBeforeClose"
        @cancel="control.moveOutShow = false">
      <div class="moveContent">
        确定搬出{{ show.moveOutName }}?
      </div>
    </van-dialog>
  </div>
</template>

<script>
import {getImageStream} from '@/utils'
import {desensitization, idCardDesensitization} from '@/utils/utils'

export default {
  data() {
    return {
      id: '',
      page: 0,
      limit: 10,
      dataList: [],
      pullLoading: false,
      loading: false,
      finished: false,
      show: {
        moveOutName: ''
      },
      control: {
        moveOutShow: false
      },
      flags: false, //控制使用
      position: {
        x: 0,
        y: 0,
      },
      nx: "",
      ny: "",
      dx: "",
      dy: "",
      xPum: "",
      yPum: "",
    }
  },
  created() {
    this.getHouse()
  },
  methods: {
    getImageStream(value) {
      return getImageStream(value)
    },
    down() {
      this.flags = true;
      var touch;
      if (event.touches) {
        touch = event.touches[0];
      } else {
        touch = event;
      }
      this.position.x = touch.clientX;
      this.position.y = touch.clientY;
      this.dx = this.$refs.fu.offsetLeft;
      this.dy = this.$refs.fu.offsetTop;
    },
    move() {
      if (this.flags) {
        var touch;
        if (event.touches) {
          touch = event.touches[0];
        } else {
          touch = event;
        }
        this.nx = touch.clientX - this.position.x;
        this.ny = touch.clientY - this.position.y;
        this.xPum = this.dx + this.nx;
        this.yPum = this.dy + this.ny;
        let width = window.innerWidth - this.$refs.fu.offsetWidth; //屏幕宽度减去自身控件宽度
        let height = window.innerHeight - this.$refs.fu.offsetHeight; //屏幕高度减去自身控件高度
        this.xPum < 0 && (this.xPum = 0);
        this.yPum < 0 && (this.yPum = 0);
        this.xPum > width && (this.xPum = width);
        this.yPum > height && (this.yPum = height);
        this.$refs.fu.style.left = this.xPum + "px";
        this.$refs.fu.style.top = this.yPum + "px";
        //阻止页面的滑动默认事件
        document.addEventListener("touchmove", function () {
          event.preventDefault();
        }, false);
      }
    },
    //鼠标释放时候的函数
    end() {
      this.flags = false;
    },
    desensitization(value) {
      return desensitization(value)
    },
    getHouse() {
      this.$toast.loading({duration: 0, message: '加载中...', forbidClick: true,});
      this.$http({
        url: this.$http.adornUrl('/wxapp/building/house/list'),
        method: 'GET',
        params: this.$http.adornParams({
          page: 1,
          limit: -1,
          userId: this.$userId,
          orgId: this.$orgId,
          loginUserId: this.$globalData.userInfo.userId,
          loginUserOrgId: this.$globalData.userInfo.orgId
        })
      }).then(({data}) => {
        this.$toast.clear()
        if (data && data.code === 0) {
          this.houseId = data.page.list.map(item => item.id)
          this.getDataList()
        } else {
          this.$toast.fail(data.msg)
        }
      })
    },
    getDataList() {
      this.page++
      this.$toast.loading({duration: 0, message: "加载中...", forbidClick: true,});
      this.$http({
        url: this.$http.adornUrl('/wxapp/building/house/tenant/list'),
        method: 'GET',
        params: this.$http.adornParams({
          page: this.page,
          limit: this.limit,
          houseId: this.houseId.toString(),
          orgId: this.$orgId,
        })
      }).then(({data}) => {
        this.$toast.clear()
        if (data && data.code === 0) {
          this.pullLoading = false
          if (this.dataList.length == data.page.totalCount) {
            this.finished = true
          }
          data.page.list.map(item => {
            this.$http({
              url: this.$http.adornUrl(`/wxapp/user/info/decode`),
              method: 'get',
              params: this.$http.adornParams({
                id: item.id,
                type: 2
              })
            }).then(({data}) => {
              if (data.code === 0) {
                item.decode = data.mobile
                item.houses = item.houses.replace(',', '：')
                item.idNumber = idCardDesensitization(item.idNumber)
              }
            })
          })
          this.dataList = this.dataList.concat(data.page.list)
          // this.dataList = this.dataList.concat(data.page.list.map(item => {
          //     return {
          //         ...item,
          //         houses: item.houses.replace(',','：'),
          //         idNumber: idCardDesensitization(item.idNumber),
          //         // mobile: desensitization(item.mobile)
          //     }
          // }))
          // 加载状态结束
          this.loading = false
          console.log('decode', this.dataList)
        } else {
          this.$toast.fail(data.msg)
        }
      })
    },
    moveOutShow(id, name, userId) {
      this.moveOutId = id
      this.control.moveOutShow = true
      this.show.moveOutName = name
      this.moveOutUserId = userId
    },
    onBeforeClose(action, done) {
      if (action === "confirm") {
        return done(false);
      } else {
        return done();
      }
    },
    moveOut(id, name) {
      // this.$dialog({
      //     message: `确认迁出${name}？`,
      //     confirmButtonText: '确认',
      //     showCancelButton: true,
      //     cancelButtonText:'取消',
      // }).then(() => {
      this.$http({
        url: this.$http.adornUrl('/wxapp/user/res/houseRes/moveOut'),
        method: 'POST',
        params: this.$http.adornParams({
          id: this.moveOutId,
          modelType: 1,
          operator: this.$userId,
          checkType: this.$globalData.userInfo.assistId ? 10 : 11
        })
      }).then(({data}) => {
        if (data && data.code === 0) {
          this.$toast.success({
            message: '操作成功',
            duration: 1500,
          }),
              setTimeout(() => {
                this.control.moveOutShow = false
                this.page = 0
                this.dataList = []
                this.getDataList()
              }, 1500)
        } else {
          this.$toast.fail(data.msg)
        }
      })
      // })
    },
    onPullDownRefresh() {
      this.finished = false;
      this.dataList = [];
      this.page = 0;
      this.getDataList();
    },
    goAdd(isSelf) {
      this.$router.push({path: '/newRegister', query: {isSelf, title: '租客登记', myHouse: 1, isList: 1}})
    },
    goUserInfo(bhrId, peopleId, houseId, relationship, relationshipStr, houseName) {
      this.$router.push({
        path: '/peopleAdd',
        query: {bhrId, peopleId, houseId, relationship, relationshipStr, houseName, title: '租客登记', myHouse: 1}
      })
    }
  }
}
</script>

<style scoped>
.van-dialog {
  width: 560px;
  border-radius: 16px;
}

.van-dialog >>> .van-dialog__content {
  margin: 38px 174px 40px 176px;
}

.moveContent {
  font-size: 30px;
  font-family: 'PingFangSC-Regular';
  font-weight: 400;
  color: #333333;
}
</style>
<style lang="scss" scoped>
.items {
  background: #FFFFFF;
  padding: 0 30px;
  margin-bottom: 16px;

  .top {
    // height: 46PX;
    // line-height: 46PX;
    border-bottom: 0.5PX solid rgba(0, 0, 0, 0.1);
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 26px 0;

    .top-left {
      max-width: 400px;
      font-size: 28px;
      font-family: 'PingFangSC-Semibold';
      font-weight: 600;
      color: #333333;
    }

    .top-right {
      font-size: 24px;
      font-family: 'PingFangSC-Regular';
      font-weight: 400;
      color: #999999;
    }
  }

  .mid {
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 188px;
    margin-top: 22px;
    padding-bottom: 26px;
    border-bottom: 1px solid rgba(0, 0, 0, 0.1);

    .mid-left {
      display: flex;
      justify-content: space-between;
      align-items: center;

      .header {
        width: 120px;
        height: 120px;
      }

      .personal-info {
        display: flex;
        flex-direction: column;
        font-size: 28px;
        font-family: 'PingFangSC-Regular';
        font-weight: 400;
        color: #666666;
        margin-left: 16px;
        margin-right: 26px;

        .name {
          font-size: 28px;
          font-family: PingFangSC-Semibold, PingFang SC;
          font-weight: 600;
          color: #333333;
        }

        .detail {
          display: flex;
          align-items: center;
          margin: 10px 0;

          .age {
            margin: 0 20px;
          }

          .phone {
            color: #4581F8;
          }
        }
      }

      .mobile {
        width: 40px;
        height: 40px;
      }
    }

    .mid-right {
      width: 16px;
      height: 28px;
    }
  }

  .down {
    height: 80px;
    // line-height: 40px;
    font-size: 24px;
    font-family: 'PingFangSC-Regular';
    font-weight: 400;
    color: #999999;
    display: flex;
    justify-content: space-between;
    align-items: center;

    .down-right {
      width: 136px;
      height: 52px;
      background: #FFFFFF;
      border-radius: 8px;
      border: 2px solid #4581F8;
      color: #4581F8;
      text-align: center;
      line-height: 52px;
    }
  }
}

.add-box {
  position: fixed;
  bottom: 72px;
  right: 0;
  width: 206px;
  height: 214px;

  .add-img {
    position: relative;

    .add-icon {
      width: 100%;
      height: 100%;
    }

    .text {
      position: absolute;
      left: 56px;
      bottom: 52px;
      font-size: 20px;
      // font-family: PingFangSC-Medium;
      font-weight: 500;
      color: #FFFFFF;
    }
  }

}
</style>
